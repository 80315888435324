import { environment } from '@environments/environment';

export class Logger {
  static log(...args: unknown[]) {
    if (environment.production) return;
    console.log(...args);
  }
  static error(...args: unknown[]) {
    if (environment.production) return;
    console.error(...args);
  }
}
